define("ember-cli-imgix/components/imgix-image", ["exports", "ember-get-config", "@imgix/js-core", "jsuri", "ember-cli-imgix/common"], function (_exports, _emberGetConfig, _jsCore, _jsuri, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Parse an aspect ratio in the format w:h to a decimal. If false is returned, the aspect ratio is in the wrong format.
   */
  function isAspectRatioValid(aspectRatio) {
    if (typeof aspectRatio !== 'string') {
      return false;
    }
    return /^\d+(\.\d+)?:\d+(\.\d+)?$/.test(aspectRatio);
  }
  var attributeMap = _objectSpread({
    src: 'src',
    srcset: 'srcset',
    sizes: 'sizes'
  }, _emberGetConfig.default.APP.imgix.attributeNameMap || {});
  var buildDebugParams = function buildDebugParams(_ref) {
    var width = _ref.width,
      height = _ref.height;
    return {
      txt64: "".concat(width != null ? width : 'auto', " x ").concat(height != null ? height : 'auto'),
      txtalign: 'center,bottom',
      txtsize: 40,
      txtfont: 'Helvetica Neue',
      txtclr: 'ffffff',
      txtpad: 40,
      txtfit: 'max'
    };
  };
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'img',
    classNameBindings: ['elementClassNames'],
    attributeBindings: ['alt', 'crossorigin', 'draggable', "src:".concat(attributeMap.src), "placeholderSrc:".concat(attributeMap.src === 'src' ? '_src' : 'src'), "srcset:".concat(attributeMap.srcset), "sizes:".concat(attributeMap.sizes)],
    path: null,
    // The path to your image
    crop: null,
    fit: 'crop',
    onLoad: null,
    onError: null,
    crossorigin: 'anonymous',
    // img element crossorigin attr
    alt: '',
    // img element alt attr
    draggable: true,
    // img element draggable attr
    options: {},
    // arbitrary imgix options
    disableLibraryParam: false,
    width: null,
    height: null,
    sizes: null,
    disableSrcSet: false,
    placeholderPath: null,
    debounceRate: 400,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.onLoad) {
        this._handleImageLoad = this._handleImageLoad.bind(this);
        this.element.addEventListener('load', this._handleImageLoad);
      }
      if (this.onError) {
        this._handleImageError = this._handleImageError.bind(this);
        this.element.addEventListener('error', this._handleImageError);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.onLoad && typeof FastBoot === 'undefined') {
        this.element.removeEventListener('load', this._handleImageLoad);
      }
      if (this.onError && typeof FastBoot === 'undefined') {
        this.element.removeEventListener('error', this._handleImageError);
      }
      this._super.apply(this, arguments);
    },
    _pathAsUri: Ember.computed('path', function () {
      if (!this.path) {
        return false;
      }
      return new _jsuri.default(this.path);
    }),
    _client: Ember.computed('disableLibraryParam', function () {
      if (!_emberGetConfig.default || !_emberGetConfig.default.APP.imgix.source) {
        throw new Ember.Error('Could not find a source in the application configuration. Please configure APP.imgix.source in config/environment.js. See https://github.com/imgix/ember-cli-imgix for more information.');
      }
      var disableLibraryParam = _emberGetConfig.default.APP.imgix.disableLibraryParam || this.disableLibraryParam;
      return new _jsCore.default({
        domain: _emberGetConfig.default.APP.imgix.source,
        includeLibraryParam: false,
        // to disable @imgix/js-core setting ixlib=js by default
        libraryParam: disableLibraryParam ? undefined : "ember-".concat(_common.constants.APP_VERSION)
      });
    }),
    _srcAndSrcSet: Ember.computed('_client', '_pathAsUri', 'crop', 'disableSrcSet', 'fit', 'height', 'options', 'path', 'width', function () {
      // Warnings, checks
      if (!this.path) {
        return;
      }
      var widthProp = this.width;
      var heightProp = this.height;
      if (isDimensionInvalid(widthProp) || isDimensionInvalid(heightProp)) {
        // eslint-disable-next-line no-console
        console.warn("[imgix] Either the width or height passed to this component (w: ".concat(widthProp, ", h: ").concat(heightProp, ") was invalid. Both width and height need to be a number greater than 0, or undefined."));
      }

      // Setup
      var pathAsUri = this._pathAsUri;
      var disableSrcSet = this.disableSrcSet;
      var client = this._client;
      var buildWithOptions = function buildWithOptions(options) {
        return client.buildURL(pathAsUri.path(), options);
      };
      var isFixedDimensionsMode = widthProp != null || heightProp != null;
      var shouldShowDebugParams = _emberGetConfig.default.APP.imgix.debug;
      var imgixOptions = this.options;
      var aspectRatio = imgixOptions.ar;
      if (aspectRatio != null && !isAspectRatioValid(aspectRatio)) {
        // false return value from isAspectRatioValid indicates invalid format
        // eslint-disable-next-line no-console
        console.warn("[imgix] The aspect ratio passed (\"".concat(aspectRatio, "\") is not in the correct format. The correct format is \"W:H\"."));
      }
      var width = widthProp;
      var height = heightProp;
      var debugParams = shouldShowDebugParams ? buildDebugParams({
        width: width,
        height: height
      }) : {};

      // Build base options
      var options = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _emberGetConfig.default.APP.imgix.defaultParams || {}), {}, {
        // Add fit from 'fit' prop
        fit: this.fit
      }, width != null ? {
        w: width
      } : {}), height != null ? {
        h: height
      } : {}), this.crop != null ? {
        crop: this.crop
      } : {}), imgixOptions), debugParams), pathAsUri.queryPairs.reduce(function (memo, param) {
        memo[param[0]] = param[1];
        return memo;
      }, {}));

      // Build src from base options
      var src = buildWithOptions(options);

      // Calculate src set (if enabled)
      var srcset = function () {
        if (disableSrcSet) {
          return;
        }

        // w-type srcsets should not be used if one of the dimensions has been fixed as it will have no effect
        if (isFixedDimensionsMode) {
          var buildWithDpr = function buildWithDpr(dpr) {
            return buildWithOptions(_objectSpread(_objectSpread({}, options), {}, {
              dpr: dpr
            }));
          };
          // prettier-ignore
          return "".concat(buildWithDpr(2), " 2x, ").concat(buildWithDpr(3), " 3x, ").concat(buildWithDpr(4), " 4x, ").concat(buildWithDpr(5), " 5x");
        } else {
          var buildSrcSetPair = function buildSrcSetPair(targetWidth) {
            var debugParams = shouldShowDebugParams ? buildDebugParams({
              width: targetWidth
            }) : {};
            var urlOptions = _objectSpread(_objectSpread(_objectSpread({}, options), debugParams), {}, {
              w: targetWidth
            });
            var url = buildWithOptions(urlOptions);
            return "".concat(url, " ").concat(targetWidth, "w");
          };
          return _common.targetWidths.map(buildSrcSetPair).join(', ');
        }
      }();
      return {
        src: src,
        srcset: srcset
      };
    }),
    src: Ember.computed.reads('_srcAndSrcSet.src'),
    srcset: Ember.computed.reads('_srcAndSrcSet.srcset'),
    placeholderSrc: Ember.computed('_client', 'placeholderPath', function () {
      if (attributeMap.src === 'src') {
        return null;
      }
      var client = this._client;
      var placeholderPathURI = new _jsuri.default(this.placeholderPath);
      var placeholderURL = client.buildURL(placeholderPathURI.path(), _objectSpread({}, placeholderPathURI.queryPairs.reduce(function (memo, param) {
        memo[param[0]] = param[1];
        return memo;
      }, {})));
      return placeholderURL;
    }),
    elementClassNames: Ember.computed('config.APP.imgix.classNames', function () {
      return _emberGetConfig.default.APP.imgix.classNames || 'imgix-image';
    }),
    _handleImageLoad: function _handleImageLoad(event) {
      var _this = this;
      Ember.run.debounce(this, function () {
        var _this$onLoad;
        return !_this.isDestroyed && ((_this$onLoad = _this.onLoad) === null || _this$onLoad === void 0 ? void 0 : _this$onLoad.call(_this, event));
      }, 500);
    },
    _handleImageError: function _handleImageError(event) {
      var _this2 = this;
      Ember.run.debounce(this, function () {
        var _this2$onError;
        return !_this2.isDestroyed && ((_this2$onError = _this2.onError) === null || _this2$onError === void 0 ? void 0 : _this2$onError.call(_this2, event));
      }, 500);
    }
  });
  function isDimensionInvalid(widthProp) {
    return widthProp != null && (typeof widthProp !== 'number' || widthProp <= 0);
  }
});