define("ember-svg-jar/inlined/Caret-Right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Icon Caret L</title><path d=\"M7.205 9L0 1.462 1.397 0 10 9l-8.603 9L0 16.538z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "18",
      "viewBox": "0 0 10 18"
    }
  };
});