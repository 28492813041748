define("ember-svg-jar/inlined/nav-icon-cart-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/><path stroke=\"#142B6F\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M20.5 6.5V23H4V6.5h16.5zM12.25 1a4.125 4.125 0 014.125 4.125c0 .314-.075.763-.225 1.347l-7.745-.047h-.071s-.209-.846-.209-1.3A4.125 4.125 0 0112.25 1z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});