define("ember-svg-jar/inlined/icon-gpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"nonzero\"><path d=\"M38.871 10.186v5.41h-1.708V2.24h4.531a4.052 4.052 0 012.93 1.161 3.867 3.867 0 01.19 5.454l-.19.192c-.797.756-1.773 1.14-2.93 1.14h-2.823zm0-6.307v4.666h2.866a2.23 2.23 0 001.687-.692 2.357 2.357 0 00-.053-3.313 2.325 2.325 0 00-1.634-.66H38.87zM49.791 6.159c1.263 0 2.26.34 2.993 1.012.732.671 1.093 1.608 1.093 2.79v5.636h-1.635v-1.268h-.074c-.71 1.044-1.645 1.566-2.823 1.566-.997 0-1.846-.298-2.515-.895a2.83 2.83 0 01-1.008-2.237c0-.948.361-1.693 1.072-2.258.711-.564 1.666-.841 2.855-.841 1.018 0 1.846.191 2.504.554v-.395c0-.585-.255-1.14-.7-1.523a2.478 2.478 0 00-1.645-.628c-.955 0-1.709.404-2.26 1.214l-1.507-.948c.806-1.182 2.026-1.779 3.65-1.779zm-2.207 6.636c0 .448.212.863.562 1.119.382.298.85.458 1.327.447a2.71 2.71 0 001.92-.799c.563-.532.85-1.16.85-1.885-.531-.426-1.274-.64-2.23-.629-.689 0-1.273.17-1.74.501-.456.33-.69.746-.69 1.246zM63.258 6.457l-5.71 13.156h-1.761l2.122-4.602-3.746-8.554h1.857l2.706 6.562h.032L61.4 6.457zM31.963 9.014c0-.522-.042-1.044-.127-1.555H24.63v2.95h4.128c-.17.948-.721 1.8-1.528 2.333v1.918h2.462c1.443-1.332 2.271-3.303 2.271-5.646z\"/><path d=\"M24.63 16.513c2.059 0 3.8-.682 5.062-1.853l-2.462-1.918c-.69.469-1.57.735-2.6.735-1.995 0-3.682-1.353-4.287-3.164h-2.536v1.982a7.629 7.629 0 006.823 4.218z\"/><path d=\"M20.343 10.313a4.645 4.645 0 010-2.94v-1.97h-2.536a7.63 7.63 0 000 6.881l2.536-1.97z\"/><path d=\"M24.63 4.21a4.104 4.104 0 012.93 1.15l2.185-2.194a7.36 7.36 0 00-5.115-1.992 7.639 7.639 0 00-6.823 4.229l2.536 1.981c.605-1.822 2.292-3.174 4.287-3.174z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "20",
      "viewBox": "0 0 80 20"
    }
  };
});