define("ember-cli-imgix/mixins/imgix-path-behavior", ["exports", "@imgix/js-core", "ember-get-config", "jsuri", "ember-cli-imgix/common"], function (_exports, _jsCore, _emberGetConfig, _jsuri, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = _exports.default = Ember.Mixin.create({
    crossorigin: null,
    aspectRatio: null,
    auto: null,
    crop: null,
    fit: null,
    pixelStep: 10,
    useParentWidth: false,
    /**
     * @public
     * @property {string} The main entry point for our component. The final `src` will be set based on a manipulation of this property.
     */
    path: null,
    /**
     * @private
     * @property {string} The computed path from the input path. This should not include any query parameters passed in, e.g. "/users/1.png?sat=100"
     */
    _path: Ember.computed('path', function () {
      var path = this.path;
      return path ? new _jsuri.default(path).path() : '';
    }),
    /**
     * @private
     * @property {Object} a hash of key-value pairs for parameters that were passed in via the `path` property
     */
    _query: Ember.computed('path', function () {
      var path = this.path;
      var query = {};
      var searchParams = new _jsuri.default(path).queryPairs;
      var _iterator = _createForOfIteratorHelper(searchParams),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          query[item[0]] = item[1];
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return path ? Ember.Object.create(query) : {};
    }),
    _widthFromPath: Ember.computed.reads('_query.w'),
    _heightFromPath: Ember.computed.reads('_query.h'),
    /**
     * @private
     * @default 0
     * @property {number} An internal counter to used to trigger resizes.
     */
    _resizeCounter: 0,
    /**
     * The main meat of our responsive imaging. We use an instance of ImgixClient to build up a new image
     * URL based on `path` and apply the correct sizing parameters as we go.
     *
     * @public
     * @property {string}
     * @return the fully built string
     */
    src: Ember.computed('_client', '_config', '_debugParams', '_dpr', '_height', '_path', '_query', '_width', 'auto', 'crop', 'fit', function () {
      if (!this._width) {
        return;
      }
      var env = this._config;

      // These operations are defaults and should be overidden by any incoming
      // query parameters
      var options = {
        fit: this.fit || 'crop'
      };
      if (this.crop) {
        Ember.merge(options, {
          crop: this.crop
        });
      }
      if (this.auto) {
        Ember.merge(options, {
          auto: this.auto
        });
      }
      if (this._query) {
        Ember.merge(options, this._query);
      }
      if (!!env && env.APP.imgix.debug) {
        Ember.merge(options, this._debugParams);
      }

      // This is where the magic happens. These are the parameters that force the
      // responsiveness that we're looking for.
      Ember.merge(options, {
        w: this._width,
        h: this._height,
        dpr: this._dpr
      });
      return this._client.buildURL(this._path, options);
    }),
    /**
     * Fire off a resize after our element has been added to the DOM.
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', this, this._incrementResizeCounter);
    },
    /**
     * Observer to trigger image resizes, but debounced.
     * @private
     */
    didResize: function didResize() {
      var debounceRate = 200;
      var env = this._config;
      if (!!env && !!env.APP.imgix.debounceRate) {
        debounceRate = env.APP.imgix.debounceRate;
      }
      Ember.run.debounce(this, this._incrementResizeCounter, debounceRate);
    },
    /**
     * @property ImgixClient instantiated ImgixClient
     * @throws {EmberError} Will throw an error if the imgix config information is not found in config/environment.js
     * @return ImgixClient return an instantiated ImgixClient instance.
     */
    _client: Ember.computed('_config', 'disableLibraryParam', function () {
      var env = this._config;
      if (!env || !env.APP.imgix.source) {
        throw new Ember.Error('Could not find a source in the application configuration. Please configure APP.imgix.source in config/environment.js. See https://github.com/imgix/ember-cli-imgix for more information.');
      }
      var disableLibraryParam = _emberGetConfig.default.APP.imgix.disableLibraryParam || this.disableLibraryParam;
      return new _jsCore.default({
        domain: env.APP.imgix.source,
        includeLibraryParam: false,
        // to disable @imgix/js-core setting ixlib=js by default
        libraryParam: disableLibraryParam ? undefined : "ember-".concat(_common.constants.APP_VERSION)
      });
    }),
    /**
     * Increments an internal resize counter, which will trigger an image resize.
     *
     * @private
     * @method _incrementResizeCounter
     */
    _incrementResizeCounter: function _incrementResizeCounter() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.incrementProperty('_resizeCounter');
    },
    /**
     * @property {Object}
     * @return {Object} a POJO with some extra imgix parameters to overlay debug data on our image.
     * @private
     */
    _debugParams: Ember.computed('_width', '_height', '_dpr', function () {
      return {
        txt64: "".concat(this._width, " x ").concat(this._height, " @ DPR ").concat(this._dpr),
        txtalign: 'center,bottom',
        txtsize: 20,
        txtfont: 'Helvetica Neue',
        txtclr: 'ffffff',
        txtpad: 20,
        txtfit: 'max',
        exp: -2
      };
    }),
    /**
     * Width as computed by the child image element's clientWidth
     * @private
     * @property _width
     * @default 0
     */
    _width: Ember.computed('_resizeCounter', '_widthFromPath', 'element.clientWidth', 'pixelStep', 'useParentWidth', function () {
      var newWidth = 0;
      if (this.useParentWidth && this.element) {
        newWidth = this.$().parent().outerWidth();
      }
      if (!newWidth) {
        newWidth = this.element.clientWidth || this._widthFromPath;
      }
      var pixelStep = this.pixelStep;
      return Math.ceil(newWidth / pixelStep) * pixelStep;
    }),
    /**
     * Height as computed by the child image element's clientHeight
     * @private
     * @property _height
     * @default 0
     */
    _height: Ember.computed('_resizeCounter', '_width', 'aspectRatio', 'element.clientHeight', function () {
      var newHeight = this.element.clientHeight || 0;
      if (this.aspectRatio) {
        newHeight = this._width / this.aspectRatio;
      }
      return Math.floor(newHeight);
    }),
    /**
     * Device Pixel Ratio as reported by the client.
     * @private
     * @property _dpr
     * @return {Number} devicePixelRatio for the client
     * @default 1
     */
    _dpr: Ember.computed('_resizeCounter', function () {
      return (0, _common.toFixed)(2, window.devicePixelRatio || 1);
    }),
    /**
     * Simple abstraction for reading the app's configuration. Useful for testing.
     * @private
     */
    _config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    })
  });
});