define("ember-svg-jar/inlined/Caret-Down-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.358 8.176L16.562.972a1.125 1.125 0 111.591 1.59l-8.795 8.796L.562 2.562A1.125 1.125 0 112.153.972l7.205 7.204z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "19",
      "height": "12",
      "viewBox": "0 0 19 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});