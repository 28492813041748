define("ember-svg-jar/inlined/icon-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#142B6F\" d=\"M8.952 1.124c-4.994 0-7.814 4.614-7.93 7.946-.052 1.518.42 3.05 1.364 4.428 1.47 2.148 3.987 3.483 6.566 3.483 4.372 0 7.929-3.557 7.929-7.929s-3.557-7.928-7.93-7.928m0 16.876c-2.91 0-5.748-1.504-7.406-3.926-1.068-1.56-1.6-3.302-1.54-5.04.149-4.312 3.78-8.93 8.947-8.93 4.934 0 8.948 4.015 8.948 8.948C17.9 13.986 13.886 18 8.952 18\" mask=\"url(#mask-2)\" transform=\"translate(3 2.735)\"/><path fill=\"#142B6F\" d=\"M13.782 12.707c.12.515.225 1.03.336 1.577l.09.44-.26-.144a55.43 55.43 0 00-.95-.519c-.203-.108-.36-.197-.484-.268-.524-.301-.565-.302-1.085-.003-.124.071-.28.16-.483.268-.308.163-.613.333-.94.515l-.273.152.004-.021c.034-.173.064-.328.096-.482l.084-.417c.072-.36.145-.731.238-1.09.079-.303.003-.525-.253-.745-.405-.348-.802-.703-1.218-1.075l-.332-.296c.142-.02.267-.039.392-.053l.414-.048c.427-.05.868-.1 1.302-.132.31-.022.508-.164.64-.46.195-.44.4-.875.607-1.31l.224-.475.04-.08.14.299c.162.348.318.681.478 1.013.082.17.144.309.194.421.234.526.273.554.83.603.122.01.272.024.46.046.349.042.698.081 1.047.12l.469.054-.343.306c-.41.365-.816.728-1.229 1.083-.235.203-.306.419-.235.721m3.188-2.074c.127-.116.23-.367.178-.528-.051-.163-.28-.308-.45-.33a83.287 83.287 0 00-2.809-.308c-.158-.015-.226-.063-.291-.207-.24-.528-.487-1.054-.733-1.58l-.38-.818c-.092-.196-.204-.451-.524-.433-.309.005-.416.239-.514.45l-.209.45c-.295.637-.6 1.295-.912 1.936-.04.081-.158.174-.235.184-.463.058-.928.109-1.392.16-.471.051-.941.102-1.411.162a.736.736 0 00-.488.293c-.177.28.094.519.195.608.173.152.341.308.51.464.356.329.724.669 1.122.959.484.352.597.699.417 1.278-.156.504-.253 1.026-.347 1.53-.04.215-.08.428-.124.636l-.002.018c-.01.22.07.4.217.49a.44.44 0 00.234.065c.1 0 .209-.03.317-.088.75-.407 1.6-.869 2.416-1.338.163-.094.266-.092.44.007.867.495 1.643.92 2.37 1.3.15.079.453.127.607.01.155-.12.194-.417.162-.586-.145-.77-.32-1.6-.55-2.615-.033-.145-.011-.215.097-.31a101 101 0 002.09-1.859\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});