define("ember-svg-jar/inlined/icon-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Icon Lock</title><g fill=\"#142B6F\" fill-rule=\"nonzero\"><path d=\"M0 8h11.9v8H0z\"/><path d=\"M3.75 8.087l-1.5-.028c.03-1.564.09-2.64.188-3.253C2.835 2.322 4.125.95 6.212.95c2.072 0 3.36 1.354 3.765 3.808.102.617.175 1.706.224 3.292l-1.5.046c-.046-1.522-.116-2.562-.204-3.094C8.202 3.216 7.474 2.45 6.212 2.45c-1.271 0-2.003.778-2.292 2.592-.084.524-.142 1.548-.17 3.045z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "16",
      "viewBox": "0 0 12 16"
    }
  };
});