define("ember-svg-jar/inlined/icon-check-yellow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icon - yes@2x</title><g fill=\"#FFD600\" fill-rule=\"nonzero\"><path d=\"M1.186 6.193a1.198 1.198 0 01.008-1.741 1.317 1.317 0 011.81.008l2.668 2.588a1.198 1.198 0 01-.008 1.741 1.317 1.317 0 01-1.811-.008L1.186 6.193z\"/><path d=\"M10.196 1.173c.5-.48 1.311-.48 1.81.001.5.482.5 1.261-.001 1.742L5.863 8.81c-.5.48-1.311.48-1.811-.002-.5-.481-.499-1.26.002-1.741l6.142-5.894z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "10",
      "viewBox": "0 0 13 10"
    }
  };
});