define("ember-svg-jar/inlined/icon-recurring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#142B6F\" d=\"M17.47 2.547a.481.481 0 00-.645.223l-.768 1.582a8.562 8.562 0 00-1.202-1.538A8.595 8.595 0 0011.556.736a8.644 8.644 0 00-4.08-.371 8.765 8.765 0 00-4.224 1.867 8.804 8.804 0 00-3.177 7.985c.134 1 .516 2.5 1.586 3.963.54.738 2.08 2.53 4.737 3.23a8.24 8.24 0 001.406.243c.21.017.416.026.62.026 2.48 0 4.614-1.203 6.01-2.26a.483.483 0 00-.582-.769c-1.377 1.042-3.53 2.242-5.97 2.042a7.288 7.288 0 01-1.238-.215c-2.424-.639-3.831-2.355-4.205-2.866a7.717 7.717 0 01-1.408-3.52 7.841 7.841 0 012.83-7.11 7.798 7.798 0 013.758-1.663 7.685 7.685 0 013.623.33 7.622 7.622 0 012.927 1.843c.429.435.8.922 1.115 1.449l-1.7-.37a.483.483 0 00-.205.942l2.408.525a.474.474 0 00.38-.081.48.48 0 00.306-.254l1.22-2.51a.482.482 0 00-.224-.645\" mask=\"url(#mask-2)\" transform=\"translate(3 3.052)\"/><path fill=\"#142B6F\" d=\"M11.385 6.883a.482.482 0 00-.482.482v5.297c0 .188.109.358.279.438l3.357 1.567a.483.483 0 00.408-.874l-3.08-1.438v-4.99a.482.482 0 00-.482-.482\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});