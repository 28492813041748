define("ember-svg-jar/inlined/icon-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#142B6F\" d=\"M6.674 11.956a15.7 15.7 0 00-.293.116l-.197-.074c-2.18-.809-5.076-2.3-5.076-5.896 0-.014 0-.027-.002-.04C1 4.795 1.442 3.586 2.351 2.655 3.358 1.624 4.83 1.033 6.389 1.033c3.006 0 5.546 2.321 5.546 5.069 0 3.816-3.822 5.296-5.261 5.854zm-1.216 2.221l1.008-1.014.02-.001c.02-.003.037-.012.056-.016l1.025 1.031h-2.11zm7.568-8.075C13.026 2.794 9.986 0 6.389 0 4.527 0 2.762.713 1.55 1.955.438 3.095-.105 4.575.018 6.122c.013 4.538 4.048 6.193 5.61 6.778l-1.389 1.397a.364.364 0 00-.076.41c.064.14.208.23.368.23h1.137a8.35 8.35 0 00-.15.76c-.142 1.062.017 1.544.5 2.254.647.954 1.605 1.642 2.846 2.05l.233-.058c.181-.176.264-.319.249-.426-.023-.154-.267-.317-.467-.427l-.168-.094c-.082-.047-.165-.093-.249-.138-1.486-.78-2.127-1.581-1.906-3.057.035-.234.097-.556.161-.864h1.779c.16 0 .305-.09.368-.23a.365.365 0 00-.076-.41L7.32 12.82c1.656-.652 5.706-2.427 5.706-6.719z\" mask=\"url(#mask-2)\" transform=\"translate(5 2)\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});