define("ember-svg-jar/inlined/Arrow-Left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Icon Arrow</title><path d=\"M5.586 8V4.993H18V3.039H5.586V0L.593 4.004z\" fill=\"#27316D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "8",
      "viewBox": "0 0 18 8"
    }
  };
});