define("ember-svg-jar/inlined/icon-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Icon Info</title><g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"8\" cy=\"8\" r=\"8\" fill=\"#142B6F\" fill-rule=\"nonzero\"/><path fill=\"#FFF\" d=\"M8.788 12.832H7.192V6.928h1.596v5.904zM7 4.996a.983.983 0 111.968 0 .978.978 0 01-.984.972A.986.986 0 017 4.996z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});