define("ember-svg-jar/inlined/icon-placebo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.cls-1{fill:none;stroke:#142b6f;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}</style></defs><title>Icon-Placebo</title><path class=\"cls-1\" d=\"M26.3 6.59L9.73 11.25a5.66 5.66 0 00-4 4 5.75 5.75 0 001.47 5.57 5.64 5.64 0 005.49 1.46l16.65-4.69a5.77 5.77 0 004-4.07 5.57 5.57 0 00-1.53-5.47 5.78 5.78 0 00-5.51-1.46z\"/><path class=\"cls-1\" d=\"M25.35 12.81L11.73 23.33a5.67 5.67 0 00-2.23 5.26 5.74 5.74 0 003.5 4.56 5.65 5.65 0 005.64-.7l13.65-10.59a5.75 5.75 0 002.17-5.25 5.56 5.56 0 00-3.46-4.5 5.78 5.78 0 00-5.64.7z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
});