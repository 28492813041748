define("ember-svg-jar/inlined/Caret-Up-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.358 3.824l7.204 7.204a1.125 1.125 0 101.591-1.59L9.358.642.562 9.438a1.125 1.125 0 101.591 1.59l7.205-7.204z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "19",
      "height": "12",
      "viewBox": "0 0 19 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});